import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { orgService } from 'src/services/orgService';
import { setOrgDetails } from 'src/redux/slices/orgSlice';
import Logo from "src/assets/images/logo.svg";
import { Container, Row, Image } from 'react-bootstrap';
import useDocumentTitle from 'src/hooks/useDocumentTitle';


function Welcome() {
    useDocumentTitle("Welcome - Awsmo");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = useSelector(state => state.user.token);

    const handleCreateOrgAndRedirect = async () => {
        if (token) {
            try {
                const orgData = await orgService.createOrganization(token);
                dispatch(setOrgDetails({
                    id: orgData.id,
                    root_user: orgData.root_user,
                    root_email: orgData.root_email,
                }));
                navigate('/awsaccount');
            } catch (error) {
                console.error('Error creating organization:', error);
            }
        } else {
            console.error('No token found');
        }
    };

    return (
        <>
            <div className='welcomeWrap'>
                <Container className='desh-height'>
                    <Row className='d-flex justify-content-center align-items-center desh-height'>
                        <div className='col-12 col-md-6 col-lg-7'></div>
                        <div className='col-12 col-md-6 col-lg-5 text-center mb-p-logo'>
                            <Image src={Logo} className='col-3' />
                            <h2>Welcome!</h2>
                            <div className='mt-4'>
                                <button onClick={handleCreateOrgAndRedirect} className="wel-link">Please connect your AWS Account</button>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Welcome;
