import React, { useState, useRef, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Logo from "src/assets/images/logo.svg";
import useDocumentTitle from 'src/hooks/useDocumentTitle';
import { toast } from 'react-hot-toast';
import { authService } from 'src/services/authService';

function Verified() {
    useDocumentTitle("Account Verified - Awsmo");
    const { uid, token } = useParams(); // Extract uid and token from the URL
    const [activationStatus, setActivationStatus] = useState({
        success: false,
        message: '',
        verifying: true // to show verifying status initially
    });
    const navigate = useNavigate();
    const logoRef = useRef();

    useEffect(() => {
        if (uid && token) {
            activateAccount(uid, token);
        }
    }, [uid, token]);

    const activateAccount = async (uid, token) => {
        try {
            await authService.activate(uid, token);
            setActivationStatus({
                success: true,
                message: 'Your account has been successfully verified. Welcome to AWSMO!',
                verifying: false
            });
        } catch (error) {
            let errorMessage = 'There was an error activating your account. Please try again.';
            if (error.response) {
                if (error.response.data.uid) {
                    errorMessage = "Invalid link or user doesn't exist. Please try again";
                    navigate('/signup');
                    toast.error(errorMessage);
                } else if (error.response.data.detail) {
                    errorMessage = "Activation link has expired. Please enter your email to resend";
                    navigate('/resend-activation');
                    toast.error(errorMessage);
                }
            }
            setActivationStatus({
                success: false,
                message: errorMessage,
                verifying: false
            });
        }
    };
    const logoClass = activationStatus.success ? 'logo-slide-up' : '';
    const messageClass = activationStatus.success ? 'text-fade-in' : '';

    return (
        <>
            <div className='loginWrap'>
                <Container className='desh-height'>
                    <Row className='d-flex justify-content-center align-items-center desh-height'>
                        <Col md={12} className='text-center mb-p-logo'>
                            <Image src={Logo} alt="Logo" ref={logoRef} className={logoClass} />
                            <div className='text-center'>
                                {activationStatus.verifying ? (
                                    <p className='f21 mt-0 text-center mb-5'>Verifying...</p>
                                ) : activationStatus.success ? (
                                    <>
                                        <div className='text-center heading-thank'>Thank you!</div>
                                        <p className={`f21 mt-0 text-center mb-5 ${messageClass}`}>
                                            {activationStatus.message}
                                        </p>
                                        <div className='text-center pt-1 mb-1'>
                                            <span className='f14'>
                                                <a href="/login" className='f14 link-color underline'>Click here to login</a>
                                            </span>
                                        </div>
                                    </>
                                ) : (
                                    <p className={`f21 mt-0 text-center mb-5 ${messageClass}`}>{activationStatus.message}</p>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Verified;
