import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from "react-bootstrap";
import Logo from 'src/assets/images/logo.svg';
import 'src/style/Dashboard.css';
import useAuth from 'src/hooks/useAuth';
import NotificationDropdown from './NotificationDropdown';

const Header = () => {
  const { performLogout } = useAuth();
  return (
    <div className='header-fix d-flex justify-content-between align-items-center'>
      <div className="d-flex justify-content-start align-items-center width-logo-sidebar pl-0">
        <div className="wrap-logo pl-lg-3">
          <Link to="/"><img src={Logo} className="img-fluid header-logo" alt="test" /></Link>
        </div>
      </div>
      <div className='d-flex align-items-center'>
        <NotificationDropdown />
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic" className='header-droparw'>
            <div className="d-flex flex-row align-items-center">
              <div className='avatar mr-2'>
                <div className='profile-icon'>R</div>
              </div>
              <div className='profile-id'><span>AWS Account ID: 363-7H...</span> <i className="bi bi-chevron-down"></i></div>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className='drop-alignment'>
            <Dropdown.Item href="#">AWS Account ID: 363-7H-HF35-HFY6700</Dropdown.Item>
            <Dropdown.Item href="#">AWS Account ID: 361-7H-HF35-HFY6700</Dropdown.Item>
            <Dropdown.Item href="#">AWS Account ID: 367-7H-HF35-HFY6700</Dropdown.Item>
            <Dropdown.Item href="#">AWS Account ID: 364-7H-HF35-HFY6700</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <button onClick={performLogout} className="btn btn-primary ml-3 logout-button-margin">Logout</button>
      </div>
    </div>
  );
}

export default Header;
