import { createSlice } from '@reduxjs/toolkit';
import { logout } from '../globalActions';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    token: null,
    isAuthenticated: false,
  },
  reducers: {
    setCredentials: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isAuthenticated = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => ({
      user: null,
      token: null,
      isAuthenticated: false,
    }));
  }
});

export const { setCredentials } = authSlice.actions;

export default authSlice.reducer;
