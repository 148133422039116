import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "./slices/authSlice";
import orgReducer from './slices/orgSlice';
import awsAccountSliceReducer from './slices/awsAccountSlice';

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

// Combine all the reducers
const rootReducer = combineReducers({
  user: authReducer,
  org: orgReducer,
  awsAccount: awsAccountSliceReducer,
  // Future slices would be added here
});

// Apply persistConfig to the rootReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export let persistor = persistStore(store);
export default store;
