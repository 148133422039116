import React, { useState, useMemo, useCallback } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { awsService } from "src/services/awsService";
import {
    Box, Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, TableSortLabel
} from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoDisturbOnRoundedIcon from '@mui/icons-material/DoDisturbOnRounded';
import CancelIcon from '@mui/icons-material/Cancel';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import ButtonGroup from '@mui/material/ButtonGroup';
import { Link } from 'react-router-dom';

function createData(instance) {
    return {
        ec2InstanceId: instance.InstanceId,
        instanceType: instance.InstanceType,
        instanceState: instance.State.Name,
        region: instance.Region,
        assignPriority: 'Low',
        status: instance.State.Name,
        autoscalingGroup: "N/A",
        action: 'Optimize',
        awsmoGroup: instance.awsmo_group || 'N/A',
    };
}

const headCells = [
    { id: 'ec2InstanceId', label: 'EC2 Instance ID', numeric: false },
    { id: 'instanceType', label: 'Instance Type', numeric: false },
    { id: 'instanceState', label: 'Instance State', numeric: false },
    { id: 'region', label: 'Region', numeric: false },
    { id: 'assignPriority', label: 'Assign Priority', numeric: false },
    { id: 'status', label: 'Status', numeric: false },
    { id: 'autoscalingGroup', label: 'Autoscaling Group', numeric: false },
    { id: 'action', label: 'Action', numeric: false },
    { id: 'awsmoGroup', label: 'Group', numeric: true },
];

const instanceStateIcons = {
    'running': <CheckCircleOutlineIcon style={{ color: 'green' }} />,
    'stopped': <DoDisturbOnRoundedIcon style={{ color: 'red' }} />,
    'terminated': <CancelIcon style={{ color: 'red' }} />,
    'default': <RadioButtonUncheckedIcon />,
};

function EnhancedTableHead({ order, orderBy, onRequestSort, visibleColumns }) {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {visibleColumns.checkbox && (
                    <TableCell padding="checkbox">
                    </TableCell>
                )}
                {headCells.map((headCell) => visibleColumns[headCell.id] && (
                    <TableCell
                        key={headCell.id}
                        align="center"
                        padding="none"
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function OptimizeButton({ onClick }) {
    return (
        <Button
            variant="contained"
            style={{
                backgroundColor: "#1976d2",
                color: "white",
                borderRadius: 20,
                textTransform: 'none',
                fontSize: '0.8125rem',
                padding: '4px 12px',
            }}
            onClick={onClick}
        >
            Optimize
        </Button>
    );
}
function EnhancedTableCell({ children, type, onClick, priority, onPriorityChange }) {
    let content = children;

    if (type === 'assignPriority') {
        content = <PriorityButtonGroup priority={priority} onChange={onPriorityChange} />;
    } else if (type === 'action' && children === 'Optimize') {
        content = <OptimizeButton onClick={onClick} />;
    }

    return (
        <TableCell align="center">
            {content}
        </TableCell>
    );
}

function PriorityButtonGroup({ priority, onChange }) {
    const handleClick = (newPriority) => {
        onChange(newPriority);
    };

    return (
        <ButtonGroup size="small" aria-label="priority button group">
            <Button
                style={{
                    backgroundColor: priority === 'Low' ? '#90caf9' : undefined,
                    color: priority === 'Low' ? '#fff' : undefined,
                }}
                onClick={() => handleClick('Low')}
            >
                Low
            </Button>
            <Button
                style={{
                    backgroundColor: priority === 'High' ? '#ef5350' : undefined,
                    color: priority === 'High' ? '#fff' : undefined,
                }}
                onClick={() => handleClick('High')}
            >
                High
            </Button>
        </ButtonGroup>
    );
}

const getUniqueFilterOptions = (data, attribute) => {
    return Array.from(new Set(data.map(item => item[attribute]))).sort();
};

const initialFilters = {
    'Instance State': [],
    'Region': [],
    'Priority': [],
    'Status': [],
    'Autoscaling group': [],
    'Group': [],
};

InstancesList.defaultProps = {
    fetchFilters: {},
    isEditVisible: false,
    handleEditClick: () => {},
    initialSelectedInstances: [],
    onSelectionChange: () => {},
    visibleColumns: {
        ...headCells.reduce((acc, curr) => ({ ...acc, [curr.id]: true }), {}),
        checkbox: false,
    },
};

export default function InstancesList({ onSelectionChange, visibleColumns, fetchFilters, isEditVisible, handleEditClick, initialSelectedInstances }) {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('ec2InstanceId');
    const [searchTerm, setSearchTerm] = useState('');
    const [isFilterActive, setIsFilterActive] = useState(false);
    const [priorities, setPriorities] = useState({});
    const [activeList, setActiveList] = useState(null);
    const [selectedFilters, setSelectedFilters] = useState(initialFilters);
    const [originalRows, setOriginalRows] = useState([]);
    const [rows, setRows] = useState([]);
    const [selected, setSelected] = useState([]);
    useEffect(() => {
        setSelected(initialSelectedInstances);
    }, [initialSelectedInstances]);

    const handleSelect = (id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
        onSelectionChange(newSelected);

    };

    // Access accountId and token from Redux store
    const accountId = useSelector((state) => state.awsAccount.accountId);
    const token = useSelector((state) => state.user.token);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await awsService.fetchSavedInstances(accountId, token, fetchFilters);
                const formattedData = data.map(item => createData(item));
                setRows(formattedData);
                setOriginalRows(formattedData); // Save the original data for resetting filters
            } catch (error) {
                console.error("Failed to fetch AWS instances:", error);
            }
        };
        if (accountId && token) {
            fetchData();
        }
    }, [accountId, token, fetchFilters]);

    const filterOptions = useMemo(() => ({
        'Instance State': getUniqueFilterOptions(rows, 'instanceState'),
        'Instance Type': getUniqueFilterOptions(rows, 'instanceType'),
        'Region': getUniqueFilterOptions(rows, 'region'),
        'Priority': getUniqueFilterOptions(rows, 'assignPriority'),
        'Status': getUniqueFilterOptions(rows, 'status'),
        'Autoscaling group': getUniqueFilterOptions(rows, 'autoscalingGroup'),
        'Group': getUniqueFilterOptions(rows, 'awsmoGroup'),
    }), [rows]);

    const clearAllFilters = () => {
        console.debug('Clearing all filters');
        setSelectedFilters(initialFilters);
        setRows(originalRows);
    };

    const applyAllFilters = useCallback(() => {
        console.debug(`Applying all filters: ${JSON.stringify(selectedFilters)}`);

        let filteredRows = rows.filter(row => {
            // Iterate over each selected filter category
            return Object.entries(selectedFilters).every(([filterCategory, filterValues]) => {
                // If no values are selected for this category, skip filtering by this category
                if (filterValues.length === 0) return true;

                // Map the human-readable filter category to the actual row attribute if necessary
                let attributeKey;
                switch (filterCategory) {
                    case 'Instance State':
                        attributeKey = 'instanceState';
                        break;
                    case 'Group':
                        attributeKey = 'awsmoGroup';
                        break;
                    default:
                        attributeKey = filterCategory.toLowerCase().replace(/\s+/g, '');
                }

                // Get current row's value for the category, ensure it's a string for consistent comparison
                const rowValue = String(row[attributeKey]).toLowerCase();

                // Check if the row's value for this category matches any of the selected filter values
                return filterValues.some(filterValue => rowValue === filterValue.toLowerCase());
            });
        });

        console.debug(`Filtered rows: ${filteredRows.length}`);
        setRows(filteredRows);
    }, [selectedFilters, originalRows]);



    const isAnyFilterSelected = Object.values(selectedFilters).some(filterArray => filterArray.length > 0);

    // Function to handle the selection of filter options
    const handleFilterOptionClick = (category, option) => {
        console.debug(`Filter option clicked: category=${category}, option=${option}`);

        setSelectedFilters(prevFilters => {
            const isOptionSelected = prevFilters[category].includes(option);
            const newFilters = {
                ...prevFilters,
                [category]: isOptionSelected
                    ? prevFilters[category].filter(existingOption => existingOption !== option)
                    : [...prevFilters[category], option],
            };

            console.debug(`Updated filters: ${JSON.stringify(newFilters)}`);
            return newFilters;
        });
    };



    // Render filter options based on the active filter category
    const renderFilterOptionsForCategory = (category) => {
        const options = filterOptions[category] || [];
        return options.map(option => (
            <li key={option} style={{
                backgroundColor: selectedFilters[category]?.includes(option) ? '#1976d2' : 'transparent',
                color: selectedFilters[category]?.includes(option) ? 'white' : 'black',
                padding: '5px 10px',
                borderRadius: '4px',
                margin: '5px',
                cursor: 'pointer',
                userSelect: 'none'
            }}
                onClick={() => handleFilterOptionClick(category, option)}
            >
                {option}
            </li>
        ));
    };



    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }
    const priorityOrder = {
        'High': 1,
        'Low': 2,
    };

    const descendingComparator = (a, b, orderBy) => {
        if (orderBy === 'assignPriority') {
            // Use the priorities state to get the current priority for comparison
            let priorityA = priorities[a.ec2InstanceId] || a.assignPriority;
            let priorityB = priorities[b.ec2InstanceId] || b.assignPriority;

            // Compare using the predefined priorityOrder
            return (priorityOrder[priorityA] || 0) - (priorityOrder[priorityB] || 0);
        } else {
            // For other fields, fall back to the default comparison
            if (b[orderBy] < a[orderBy]) {
                return -1;
            }
            if (b[orderBy] > a[orderBy]) {
                return 1;
            }
            return 0;
        }
    };

    const getComparator = (order, orderBy, priorities) => {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy, priorities)
            : (a, b) => -descendingComparator(a, b, orderBy, priorities);
    };


    const handlePriorityChange = (ec2InstanceId, newPriority) => {
        setPriorities((prevPriorities) => ({
            ...prevPriorities,
            [ec2InstanceId]: newPriority,
        }));
    };

    const handleSearchChange = (event) => {
        const value = event.target.value;
        // Make sure the value is defined before converting to lower case
        setSearchTerm(value ? value.toLowerCase() : '');
    };
    const filteredRows = searchTerm === ''
        ? rows // If search term is empty, return all rows
        : rows.filter((row) =>
            searchTerm.split(' ').every((term) => // Split search term by space and ensure every term matches
                term === '' || (
                    row.ec2InstanceId.toLowerCase().includes(term) ||
                    (row.region && row.region.toLowerCase().includes(term)) ||
                    (row.status && row.status.toLowerCase().includes(term)) ||
                    (row.autoscalingGroup && row.autoscalingGroup.toLowerCase().includes(term)) ||
                    (row.instanceState && row.instanceState.toLowerCase().includes(term)) ||
                    (priorities[row.ec2InstanceId] && priorities[row.ec2InstanceId].toLowerCase().includes(term)) ||
                    (row.awsmoGroup && row.awsmoGroup.toLowerCase().includes(term))
                )
            )
        );
    // Define mapping from visibleColumns keys to filter categories
    const columnFilterMapping = {
        'instanceState': 'Instance State',
        'instanceType': 'Instance Type',
        'region': 'Region',
        'assignPriority': 'Priority',
        'status': 'Status',
        'autoscalingGroup': 'Autoscaling group',
        'awsmoGroup': 'Group',
    };

    // Generate listFilter based on visibleColumns and columnFilterMapping
    const listFilter = Object.entries(visibleColumns).reduce((acc, [key, isVisible]) => {
        if (isVisible && columnFilterMapping[key]) {
            acc.push(columnFilterMapping[key]);
        }
        return acc;
    }, []);

    const tableRowRender = (row, labelId) => (
        <TableRow
            hover
            role="checkbox"
            tabIndex={-1}
            key={row.ec2InstanceId}
            selected={selected.indexOf(row.ec2InstanceId) !== -1}
        >
            {visibleColumns['checkbox'] !== false && (
                <TableCell padding="checkbox">
                    <Checkbox
                        checked={selected.indexOf(row.ec2InstanceId) !== -1}
                        onChange={() => handleSelect(row.ec2InstanceId)}
                    />
                </TableCell>
            )}
            {
                visibleColumns['ec2InstanceId'] !== false &&
                <TableCell component="th" id={labelId} scope="row" padding="none" align="center">
                    {/* Modify the Link component's style to indicate it's clickable */}
                    <Link to={`/instancedetail/${row.ec2InstanceId}`}
                        style={{
                            textDecoration: 'none',
                            color: '#1976d2', // A shade of blue to indicate link
                            fontWeight: 'bold'
                        }}
                        // Additional styling for hover state
                        onMouseOver={({ target }) => target.style.textDecoration = 'underline'}
                        onMouseOut={({ target }) => target.style.textDecoration = 'none'}>
                        {row.ec2InstanceId}
                    </Link>
                </TableCell>
            }
            {
                visibleColumns['instanceType'] !== false &&
                <TableCell align="center">{row.instanceType}</TableCell>
            }
            {
                visibleColumns['instanceState'] !== false &&
                <TableCell align="center">{instanceStateIcons[row.instanceState] || '-'}</TableCell>
            }
            {
                visibleColumns['region'] !== false &&
                <TableCell align="center">{row.region}</TableCell>
            }
            {
                visibleColumns['assignPriority'] !== false &&
                <EnhancedTableCell
                    type="assignPriority"
                    priority={priorities[row.ec2InstanceId] || 'Low'}
                    onPriorityChange={(newPriority) => handlePriorityChange(row.ec2InstanceId, newPriority)}
                />
            }
            {
                visibleColumns['status'] !== false &&
                <EnhancedTableCell align="center">{row.status}</EnhancedTableCell>
            }
            {
                visibleColumns['autoscalingGroup'] !== false &&
                <TableCell align="center">{row.autoscalingGroup}</TableCell>
            }
            {
                visibleColumns['action'] !== false &&
                <EnhancedTableCell type="action" align="center">{row.action}</EnhancedTableCell>
            }
            {
                visibleColumns['awsmoGroup'] !== false &&
                <TableCell align="center">{row.awsmoGroup}</TableCell>

            }
        </TableRow>
    );
    return (
        <div className="bg-white p-3 p-lg-4 desh-height rounded-cust border-purple">
            <div className='row'>
                <div className='col-12'>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {isEditVisible && (
                            <Tooltip title="Edit Instances">
                                <IconButton
                                    onClick={handleEditClick}
                                    size="small"
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        )}

                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '0.5rem' }}>
                        <div>
                            <div style={{ fontSize: '1.125rem', fontWeight: 'bold', marginBottom: '8px' }}>
                                List of Instances
                            </div>
                            <div className='d-flex justify-content-between align-items-center mb-2 posi-rel'>
                                <div><Button variant="outline-primary" size="sm" onClick={() => setIsFilterActive(true)}><i className="bi bi-funnel"></i> Filter</Button>
                                </div>
                                {isFilterActive && (
                                    <div className='bg-filter-wrap'>
                                        <div className='wrap-header d-flex justify-content-between align-items-center'>
                                            <div className='text'><i className="bi bi-funnel"></i> Filters</div>
                                            <div className='font-weight-bold f13 cursor' onClick={() => setIsFilterActive(false)}>Close</div>
                                        </div>
                                        <div className='d-flex justify-content-start align-items-top'>
                                            <div>
                                                <ul className='filter-category-list'>
                                                    {listFilter.map((category) => (
                                                        <li
                                                            key={category}
                                                            onClick={() => setActiveList(category)}
                                                            className={`filter-category-item ${activeList === category ? "active" : ""}`}
                                                            style={{
                                                                fontWeight: activeList === category ? 'bold' : 'normal',
                                                            }}
                                                        >
                                                            {category} <span className='filter-count'>{selectedFilters[category]?.length || 0}</span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <div>
                                                {activeList && (
                                                    <ul className='filter-options-list'>
                                                        {renderFilterOptionsForCategory(activeList)}
                                                    </ul>
                                                )}
                                            </div>
                                        </div>
                                        <div className='filter-footer d-flex justify-content-between align-items-center p-2 border-t-1'>
                                            <Button variant="outlined" size="small" onClick={clearAllFilters} disabled={!isAnyFilterSelected}>
                                                Clear Filters
                                            </Button>
                                            <Button variant="contained" size="small" onClick={applyAllFilters} disabled={!isAnyFilterSelected}>
                                                Apply all
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='d-flex'>
                            <div className='search-wrap'>
                                <input
                                    type="text"
                                    className='search-input'
                                    placeholder='Find instances by attribute or tag (case-sensitive)'
                                    onChange={handleSearchChange}
                                />
                                <button type='button' className='search-btn'>
                                    <i className="bi bi-search"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='dash-table'>
                        <Box sx={{ width: '100%' }}>
                            <Paper sx={{ width: '100%', mb: 2 }}>
                                <TableContainer>
                                    <Table
                                        sx={{ minWidth: 750 }}
                                        aria-labelledby="tableTitle"
                                        size={'medium'}
                                    >
                                        <EnhancedTableHead
                                            order={order}
                                            orderBy={orderBy}
                                            onRequestSort={handleRequestSort}
                                            rowCount={filteredRows.length}
                                            visibleColumns={visibleColumns}
                                        />
                                        <TableBody>
                                            {stableSort(filteredRows, getComparator(order, orderBy, priorities))
                                                .map((row, index) => {
                                                    const labelId = `enhanced-table-checkbox-${index}`;
                                                    return tableRowRender(row, labelId);
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Box>
                    </div>
                </div>
            </div>
        </div >
    );
}
