import React from 'react';
import { useSelector } from 'react-redux';
import 'src/style/graphs.css';
import InstancesList from './InstancesList';

function AccountAWSID(props) {
  const { accountId } = useSelector((state) => state.awsAccount);

  return (
    <div className='margin-top-dash font-open'>
      <div className={`h-scroller rightContainer ${props.inactive ? "inactive" : ""}`}>
        <div className='m-3 m-lg-4'>
          <div className="bg-white p-3 p-lg-4 desh-height rounded-cust border-purple mb-3 mb-lg-4">
            <div className='mb-3'>
              <div className='text-black f18 font-open font-weight-bold'>
                AWS Account ID - {accountId}
              </div>
            </div>
            <div className='msg-instance mb-3'>Cumulative analysis of all Instances</div>
            <div className='row'>
              <div className='col-md-6 col-lg-3 col-xl-3 col-xxl-3'>
                <div className='wrap-details-acct'>
                  <div className='innerWrap'>
                    <div className='text'>CURRENT MONTH SPEND</div>
                    <div className='prize'>$ 5,657.01</div>
                  </div>
                </div>
              </div>
              <div className='col-md-6 col-lg-3 col-xl-3 col-xxl-3'>
                <div className='wrap-details-acct'>
                  <div className='innerWrap'>
                    <div className='text'>MONTHLY FORECAST</div>
                    <div className='prize'>$ 4,257.8</div>
                  </div>
                </div>
              </div>
              <div className='col-md-6 col-lg-3 col-xl-3 col-xxl-3'>
                <div className='wrap-details-acct'>
                  <div className='innerWrap'>
                    <div className='text'>CURRENT YEAR SAVING</div>
                    <div className='prize'>$ 18,700</div>
                  </div>
                </div>
              </div>
              <div className='col-md-6 col-lg-3 col-xl-3 col-xxl-3'>
                <div className='wrap-details-acct'>
                  <div className='innerWrap'>
                    <div className='text'>7 DAY FORECAST SAVING</div>
                    <div className='prize'>$ 5,657</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <InstancesList />
        </div>
      </div>
    </div>
  );
}

export default AccountAWSID;
