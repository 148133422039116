import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { awsService } from "src/services/awsService";
import Refresh from "src/assets/images/refresh.svg";
import CpuUtilization from './graphs/LineGraphcputilization.js';
import LineStatusCheckFailed from './graphs/LineStatusCheckFailed';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import DoDisturbOnRoundedIcon from '@mui/icons-material/DoDisturbOnRounded';



// Utility function to format timestamp
function formatTimestamp(timestamp) {
  const date = new Date(timestamp);
  return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()} | ${date.getHours() % 12 || 12}:${date.getMinutes().toString().padStart(2, '0')} ${date.getHours() >= 12 ? 'pm' : 'am'}`;
}

// Data structure for table headers
const headCells = [
  { id: 'dateTimeOfOptimization', label: 'Date/Time of Optimization' },
  { id: 'instanceID', label: 'Instance ID' },
  { id: 'instanceState', label: 'Instance State' },
  { id: 'instanceType', label: 'Instance Type' },
  { id: 'purchaseType', label: 'Purchase Type' },
  { id: 'autoscalingGroup', label: 'Autoscaling Group' },
  { id: 'duration', label: 'Duration' },
  { id: 'costPerHour', label: 'Cost Per Hour' },
];

function InstanceDetail() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [instanceHistoryData, setInstanceHistoryData] = useState([]);
  const accountId = useSelector((state) => state.awsAccount.accountId);
  const token = useSelector((state) => state.user.token);
  const { instanceId } = useParams();

  useEffect(() => {
    const fetchHistory = async () => {
      const data = await awsService.fetchInstanceHistory(accountId, instanceId, token);
      setInstanceHistoryData(data.map(item => ({
        dateTimeOfOptimization: formatTimestamp(item.timestamp),
        instanceID: item.instance,
        instanceState: item.instance_state,
        instanceType: item.instance_type,
        purchaseType: "On Demand",
        autoscalingGroup: "ASG-1",
        duration: item.duration,
        costPerHour: "$ 0.12"
      })));
    };

    if (show) {
      fetchHistory();
    }
  }, [show, accountId, token]);


  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleBack = () => navigate(-1);

  return (
    <>
      <div className='margin-top-dash font-open'>
        <div className={`h-scroller rightContainer $ {inactive ? "inactive" : ""}`}>
          <div className='p-3 p-lg-4'>
            <div className="bg-white p-3 p-lg-4 desh-height rounded-cust border-purple mb-3 mb-lg-4">
              <div className='d-flex justify-content-between align-items-center mb-3 flex-wrap'>
                <div className='text-black f18 font-open font-weight-bold'>
                  <button onClick={handleBack} className='back-button' style={{ border: 'none', background: 'none' }}>
                    <ArrowBackIosNewIcon />
                  </button>
                  <span style={{ marginLeft: '10px' }}>EC2 Instance ID : {instanceId}</span>
                </div>
                <div>
                  <a href="#!" className='f13' onClick={handleShow}>
                    View History of Optimization for this Instance
                    <img src={Refresh} alt="Refresh" className='w24 ms-1' />
                  </a>
                </div>
              </div>
            </div>
            {/* Visualization and Interactive Elements */}
            <div className='row'>
              {/* Current Month Spend, Monthly Forecast, etc. */}
              <div className='col-md-6 col-lg-3 mb-3'>
                <div className='card'>
                  <div className='card-body'>
                    <h5 className='card-title'>CURRENT MONTH SPEND</h5>
                    <p className='card-text'>$ 5,657.01</p>
                  </div>
                </div>
              </div>
              <div className='col-md-6 col-lg-3 mb-3'>
                <div className='card'>
                  <div className='card-body'>
                    <h5 className='card-title'>MONTHLY FORECAST</h5>
                    <p className='card-text'>$ 4,257.8</p>
                  </div>
                </div>
              </div>
              <div className='col-12'>
                <div className='card'>
                  <div className='card-body'>
                    <h5 className='card-title'>CPU Utilization (%)</h5>
                    <CpuUtilization />
                  </div>
                </div>
              </div>
              <div className='col-12 mt-3'>
                <div className='card'>
                  <div className='card-body'>
                    <h5 className='card-title'>Status Check Failed (any) (count)</h5>
                    <LineStatusCheckFailed />
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered size="xl">
        <Modal.Header closeButton>
          <Modal.Title>History of Optimization</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: 'auto' }}>
          <Box sx={{ width: 'auto' }}>
            <Paper sx={{ mb: 2, overflowX: 'auto' }}>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead style={{ backgroundColor: '#f5f5f5' }}>
                    <TableRow>
                      {headCells.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          align="center"
                          style={{ fontWeight: 'bold', fontSize: '1rem', whiteSpace: 'nowrap' }}
                        >
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {instanceHistoryData.map((row, index) => {
                      const stateIcon = {
                        'running': <CheckCircleOutlineIcon style={{ color: 'green' }} />,
                        'stopped': <DoDisturbOnRoundedIcon style={{ color: 'red' }} />,
                        'terminated': <CancelIcon style={{ color: 'red' }} />,
                        'default': <RadioButtonUncheckedIcon />,
                      }[row.instanceState] || <RadioButtonUncheckedIcon />;

                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                          {Object.keys(row).map((cell) => (
                            <TableCell key={cell} align="center" style={{ whiteSpace: 'nowrap' }}>
                              {cell === 'instanceState' ? stateIcon : row[cell]}
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </Modal.Body>
      </Modal>

    </>
  );
}

export default InstanceDetail;