import React, { useRef, useState } from 'react';
import Sidebar from 'src/components/dashboard/Sidebar';
import Header from 'src/components/dashboard/Header';
import AddAccount from 'src/components/dashboard/AddAccount';
const AWSAccount = () => {
  const [inactive, setInactive] = useState(false);
  const [isColActive, setColActive] = useState('d');
  const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent);
  const inputRef = useRef(null)
  const setColumnActive = (e) => {
    if (isMobile === true && e !== 'p') {
      inputRef.current.click()
    }
    setColActive(e);
  }
  return (
    <>
      <header>
        <Sidebar takeRef={inputRef} parentCallBack={setColumnActive} isColActive={isColActive} onCollapse={(inactive) => {
          setInactive(inactive);
        }}
        />
        <Header parentCallBack={setColumnActive} isColActive={isColActive} />
      </header>
      <AddAccount inactive={inactive} />
    </>

  )
}

export default AWSAccount