// src/routes/PublicRoute.js
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const PublicRoute = ({ component: Component, ...rest }) => {
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

    if (isAuthenticated) {
        // Redirect authenticated users to the dashboard
        return <Navigate to="/" />;
    }

    return <Component {...rest} />;
};

export default PublicRoute;
