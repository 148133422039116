import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Chip, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Select, Stack, Switch, TextField, Typography } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AddIcon from '@mui/icons-material/Add';
import toast from 'react-hot-toast';
import InstancesList from './InstancesList';
import { awsService } from 'src/services/awsService';
import MaintenanceWindowModal from './MaintenanceWindowModal';

function CreateAwsmoGroup() {
    const navigate = useNavigate();
    const awsAccount = useSelector((state) => state.awsAccount);
    const token = useSelector((state) => state.user.token);
    const [groupDetails, setGroupDetails] = useState({
        maintenance_window_ids: [],
        optimized: true,
        name: '',
    });
    const [selectedInstanceIds, setSelectedInstanceIds] = useState([]);
    const [maintenanceWindows, setMaintenanceWindows] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => {
        setIsModalOpen(false);
    };


    useEffect(() => {
        const fetchMaintenanceWindows = async () => {
            try {
                const data = await awsService.fetchMaintenanceWindows(token);
                setMaintenanceWindows(data);
            } catch (error) {
                toast.error('Failed to fetch maintenance windows.');
            }
        };

        if (!isModalOpen) {
            fetchMaintenanceWindows();
        }
    }, [token, isModalOpen]);


    const handleSaveGroup = async () => {
        if (selectedInstanceIds.length === 0) {
            toast.error('Please select at least one instance.');
            return;
        }

        const payload = {
            aws_account_id: awsAccount.accountId,
            instance_ids: selectedInstanceIds,
            name: groupDetails.name,
            maintenance_window_ids: groupDetails.maintenance_window_ids,
            optimized: groupDetails.optimized,
        };
        try {
            const data = await awsService.createAwsmoGroup(payload, token);
            toast.success('Group created successfully!');

            // Reset the form fields to default values
            setGroupDetails({
                maintenance_window_ids: [],
                optimized: true,
                name: '',
            });

            setSelectedInstanceIds([]);

        } catch (error) {
            toast.error('Failed to create group. Please try again.');
        }
    };

    const handleChange = (e) => {
        if (e.target.value.includes("create-new")) {
            openModal();
        } else {
            setGroupDetails({ ...groupDetails, [e.target.name]: e.target.value });
        }
    };


    const handleOptimizedToggle = (event) => {
        setGroupDetails({ ...groupDetails, optimized: event.target.checked });
    };

    const canSave = groupDetails.name.length > 0 && selectedInstanceIds.length > 0 && groupDetails.maintenance_window_ids.length > 0;

    const visibleColumns = {
        ec2InstanceId: true,
        instanceState: true,
        region: true,
        instanceType: true,
        status: true,
        assignPriority: false,
        autoscalingGroup: false,
        action: false,
        awsmoGroup: false,
        checkbox: true,
    };

    const fetchFilters = {
        awsmo_group: null,
    };
    const instanceChips = selectedInstanceIds.map((instance) => (
        <Chip
            key={instance}
            label={instance}
            onDelete={() => setSelectedInstanceIds(selectedInstanceIds.filter((id) => id !== instance))}
            sx={{ m: 0.5 }}
        />
    ));

    return (
        <Box className='margin-top-dash font-open'>
            <Box className='h-scroller rightContainer'>
                <Box className='m-3 m-lg-4'>
                    <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
                        <Button startIcon={<ArrowBackIosNewIcon />} onClick={() => navigate(-1)}>
                            Back
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!canSave || selectedInstanceIds.length === 0}
                            onClick={handleSaveGroup}
                            borderRadius={3}
                        >
                            Save Group
                        </Button>
                    </Stack>
                    <Paper elevation={4} sx={{ p: 3, borderRadius: 2 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h5">Create New Group</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <TextField
                                        required
                                        name="name"
                                        label="Group Name"
                                        value={groupDetails.name}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={groupDetails.optimized}
                                            onChange={handleOptimizedToggle}
                                            name="optimized"
                                        />
                                    }
                                    label="Optimized"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="maintenance-window-label">Maintenance Window</InputLabel>
                                    <Select
                                        labelId="maintenance-window-label"
                                        name="maintenance_window_ids"
                                        multiple
                                        value={groupDetails.maintenance_window_ids}
                                        onChange={handleChange}
                                        label="Maintenance Window"
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={maintenanceWindows.find(window => window.id === value)?.name || value} />
                                                ))}
                                            </Box>
                                        )}
                                    >
                                        {maintenanceWindows.map((window) => (
                                            <MenuItem key={window.id} value={window.id}>
                                                {window.name}
                                            </MenuItem>
                                        ))}
                                        <MenuItem value="create-new">
                                            <em onClick={openModal}> + Create New Maintenance Window</em>
                                        </MenuItem>
                                    </Select>

                                </FormControl>
                            </Grid>
                        </Grid>
                    </Paper>
                    <MaintenanceWindowModal open={isModalOpen} handleClose={closeModal} window={null} />

                    {
                        selectedInstanceIds.length > 0 ? (
                            <Box sx={{ mt: 3, mb: 2 }}>
                                <Typography variant="h7" fontWeight="bold">
                                    Selected Instances: {instanceChips}
                                </Typography>

                            </Box>
                        ) : (
                            <Box sx={{ mt: 4, mb: 3 }}>
                                <Typography variant="h7" fontWeight="bold">
                                    No instances selected
                                </Typography>
                            </Box>
                        )
                    }

                    <InstancesList
                        onSelectionChange={(instanceIds) => setSelectedInstanceIds(instanceIds)}
                        visibleColumns={visibleColumns}
                        fetchFilters={fetchFilters}
                    />
                </Box>
            </Box>
        </Box>
    );
}

export default CreateAwsmoGroup;
