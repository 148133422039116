import React, { useState } from 'react';
import { Container, Row, Image, Button, FloatingLabel, Form } from 'react-bootstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { authService } from 'src/services/authService';
import Logo from "src/assets/images/logo.svg";
import showPwdImg from 'src/assets/images/eye.svg';
import hidePwdImg from 'src/assets/images/eye_crossed.svg';
import useDocumentTitle from 'src/hooks/useDocumentTitle';


function ResetPassword() {
    useDocumentTitle("Reset Password - Awsmo");
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [isRevealConfirmPwd, setIsRevealConfirmPwd] = useState(false);
    const { uid, token } = useParams();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newPassword = e.target.elements.password.value;
        const reNewPassword = e.target.elements['confirm-password'].value;

        if (newPassword !== reNewPassword) {
            alert("Passwords do not match");
            return;
        }

        try {
            const status = await authService.resetPasswordConfirm(newPassword, reNewPassword, uid, token);
            if (status === 204) {
                alert('Password reset successfully');
                navigate('/login');
            } else {
                alert('An error occurred');
            }
        } catch (error) {
            console.error("Failed to reset password", error);
        }
    };

    return (
        <div className='loginWrap'>
            <Container className='desh-height'>
                <Row className='d-flex justify-content-center align-items-center desh-height'>
                    <div className='col-12 col-md-6 col-lg-7 text-center mb-p-logo'>
                        <div className='mr-login'>
                            <Image src={Logo} />
                        </div>
                    </div>
                    <div className='col-12 col-md-6 col-lg-5 col-xxl-4'>
                        <div className='signupBox'>
                            <h1>Reset Password</h1>
                            <form onSubmit={handleSubmit}>
                                <FloatingLabel label="Password" className="mb-2 passwrap">
                                    <Form.Control
                                        name="password"
                                        type={isRevealPwd ? "text" : "password"}
                                        placeholder="Password"
                                    />
                                    <img
                                        alt={isRevealPwd ? "Hide password" : "Show password"}
                                        src={isRevealPwd ? hidePwdImg : showPwdImg}
                                        onClick={() => setIsRevealPwd(prevState => !prevState)}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </FloatingLabel>
                                <FloatingLabel label="Confirm Password" className="mb-2 passwrap">
                                    <Form.Control
                                        name="confirm-password"
                                        type={isRevealConfirmPwd ? "text" : "password"}
                                        placeholder="Confirm Password"
                                    />
                                    <img
                                        alt={isRevealConfirmPwd ? "Hide password" : "Show password"}
                                        src={isRevealConfirmPwd ? hidePwdImg : showPwdImg}
                                        onClick={() => setIsRevealConfirmPwd(prevState => !prevState)}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </FloatingLabel>
                                <Button type="submit" variant="primary" className='mt-4 w-100'>Reset Password</Button>
                            </form>
                            <div className='text-center pt-4 mb-1'>
                                <Link to="/" className='f14 link-color'>Cancel</Link>
                            </div>
                        </div>
                    </div>
                </Row>
            </Container>
        </div>
    );
}

export default ResetPassword;
