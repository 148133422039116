import React from 'react';
import { Navigate } from 'react-router-dom';
import LoadingScreen from 'src/components/dashboard/Loading';
import useAuth from 'src/hooks/useAuth';
import useEnsureData from 'src/hooks/useEnsureData';

const ProtectedRoute = ({ component: Component, needsData = false }) => {
    const { isAuthenticated, isLoading: isLoadingAuth } = useAuth();
    const { isLoading: isLoadingData, loadingMessage } = useEnsureData(isAuthenticated && needsData);

    if (!isAuthenticated) {
        // Redirect to login if not authenticated
        return <Navigate to="/login" replace />;
    }
    if (isLoadingAuth || isLoadingData) {
        // Show loading screen with the current loading message
        return <LoadingScreen message={loadingMessage} />;
    }

    // Render the component if authenticated and (if needed) data is loaded
    return <Component />;
};

export default ProtectedRoute;
