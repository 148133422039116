import React, { useState } from 'react';
import { Container, Row, Image, Button, FloatingLabel, Form } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import Logo from "src/assets/images/logo.svg";
import showPwdImg from 'src/assets/images/eye.svg';
import hidePwdImg from 'src/assets/images/eye_crossed.svg';
import { authService } from 'src/services/authService';
import { toast } from 'react-hot-toast';
import useDocumentTitle from 'src/hooks/useDocumentTitle';

function SignUp() {
    useDocumentTitle("Sign Up - Awsmo");
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [isSignupPwd, setIsSignupPwd] = useState(false);
    const navigate = useNavigate();

    const handleSignup = (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            toast.error("Passwords do not match.");
            return;
        }

        const attemptSignup = () => authService.signup({
            email,
            password,
            re_password: confirmPassword,
            first_name: firstName,
            last_name: lastName
        });

        toast.promise(
            attemptSignup(),
            {
                loading: 'Signing up...',
                success: () => {
                    navigate('/login');
                    return 'Signup successful! Please check your email to activate your account.';
                },
                error: (err) => {
                    if (err?.response?.status === 400 && err.response.data.email) {
                        return err.response.data.email[0];
                    }
                    // General error message for other types of errors
                    return 'An error occurred during signup. Please try again later.';
                },
            },
            {
                success: {
                    duration: 5000,
                    icon: '🎉',
                },
                error: {
                    duration: 5000,
                    icon: '⚠️',
                },
            }
        );
    };

    return (
        <>
            <div className='loginWrap'>
                <Container className='desh-height'>
                    <Row className='d-flex justify-content-center align-items-center desh-height'>
                        <div className='col-12 col-md-6 col-lg-7 text-center mb-p-logo'>
                            <div className='mr-login'>
                                <Image src={Logo} />
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-5 col-xxl-4'>
                            <div className='signupBox'>
                                <h1 className='text-center'>Create Your Account</h1>
                                <p className='f14 subtext'>You will receive a verification link on your email address. Click the verification link to verify your email and complete your registration.</p>
                                <form onSubmit={handleSignup}>
                                    <FloatingLabel label="Email address" className="mb-2">
                                        <Form.Control
                                            className="form-style"
                                            type="email"
                                            placeholder="name@example.com"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </FloatingLabel>
                                    <FloatingLabel label="Password" className="mb-2 passwrap">
                                        <Form.Control
                                            className="form-style"
                                            type={isRevealPwd ? "text" : "password"}
                                            id="signupPassword"
                                            placeholder="Password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <img
                                            alt={isRevealPwd ? "Hide password" : "Show password"}
                                            title={isRevealPwd ? "Hide password" : "Show password"}
                                            src={isRevealPwd ? hidePwdImg : showPwdImg}
                                            onClick={() => setIsRevealPwd(prevState => !prevState)}
                                        />
                                    </FloatingLabel>
                                    <FloatingLabel label="Confirm Password" className="mb-2 passwrap">
                                        <Form.Control
                                            className="form-style"
                                            type={isSignupPwd ? "text" : "password"}
                                            id="confirmPassword"
                                            placeholder="Confirm Password"
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                        />
                                        <img
                                            alt={isSignupPwd ? "Hide password" : "Show password"}
                                            title={isSignupPwd ? "Hide password" : "Show password"}
                                            src={isSignupPwd ? hidePwdImg : showPwdImg}
                                            onClick={() => setIsSignupPwd(prevState => !prevState)}
                                        />
                                    </FloatingLabel>
                                    <FloatingLabel label="First Name" className="mb-2">
                                        <Form.Control
                                            className="form-style"
                                            type="text"
                                            placeholder="First Name"
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                        />
                                    </FloatingLabel>
                                    <FloatingLabel label="Last Name" className="mb-2">
                                        <Form.Control
                                            className="form-style"
                                            type="text"
                                            placeholder="Last Name"
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                        />
                                    </FloatingLabel>

                                    <Button variant="primary" className='mt-3 w-100' onClick={handleSignup}>Create</Button>
                                    <div className='text-center'>
                                        <span className='f14'>Already have an account? <Link to="/login" className='f14 link-color'>Log In</Link></span>
                                    </div>
                                    <div className='text-center f14 mt-4 mt-md-4'>
                                        By continuing, you are agreeing to the <a href="#" className='link-color'>Privacy Policy</a> and <a href="#" className='link-color'>Terms and Conditions</a> of AWSMO
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default SignUp
