import React from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import Logo from "src/assets/images/logo.svg";
import useAuth from 'src/hooks/useAuth';

const NotFound = () => {
  const { isAuthenticated } = useAuth();

  return (
    <div className="pageWrap" style={{ backgroundColor: "#f8f9fa", minHeight: "100vh" }}>
      <Container className="d-flex justify-content-center align-items-center vh-100">
        <Row className="text-center">
          <Col>
            <Image src={Logo} alt="Logo" style={{ maxWidth: '120px', margin: '0 auto' }} className="mb-4" />
            <h1 style={{ fontSize: '2.5rem', fontWeight: 'bold' }}>404 - Page Not Found</h1>
            <p style={{ fontSize: '1.2rem' }}>The page you are looking for does not exist.</p>

            {isAuthenticated ? (
              <>
                <p style={{ fontSize: '1rem', margin: '20px 0' }}>You can go back to your dashboard to access your account.</p>
                <Button href="/" variant="primary" style={{ padding: '10px 30px' }}>Dashboard</Button>
              </>
            ) : (
              <>
                <p style={{ fontSize: '1rem', margin: '20px 0' }}>If you already have an account, you can log in to access your dashboard.</p>
                <Button href="/login" variant="primary" style={{ padding: '10px 30px', marginRight: '20px' }}>Login</Button>
                <p style={{ fontSize: '1rem', margin: '20px 0' }}>Or, if you're new here, sign up to get started.</p>
                <Button href="/signup" variant="secondary" style={{ padding: '10px 30px' }}>Sign Up</Button>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NotFound;
