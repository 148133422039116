import React, { useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
const data = [
  {
    time: '08:00',
    percentage: 21,
    amt: 24,
  },
  {
    time: '08:15',
    percentage: 10,
    amt: 22,
  },
  {
    time: '08:30',
    percentage: 48,
    amt: 22,
  },
  {
    time: '08:40',
    percentage: 39,
    amt: 20,
  },
  {
    time: '08:55',
    percentage: 48,
    amt: 21,
  },
];
function LineGraphcputilization() {
  useEffect(() => {
    document.title = "AWSMO"

  }, [])
  return (
    <ResponsiveContainer width="100%" aspect={2}>
      <LineChart
        width={300}
        height={240}
        data={data}
        margin={{
          top: 5,
          right: 20,
          left: -36,
          bottom: -5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="time" style={{ fontSize: '13px' }} />
        <YAxis style={{ fontSize: '13px' }} />
        <Tooltip />
        {/* <Legend /> */}
        <Line type="monotone" dataKey="percentage" stroke="#8884d8" activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer>
  );
}

export default LineGraphcputilization;