import api from './api';

const login = async (email, password) => {
  const response = await api.post('/auth/jwt/create/', { "email": email, "password": password });
  return response.data;
};

const resendActivationMail = async (email) => {
  try {
    const response = await api.post('/auth/users/resend-activation/', { email });
    return { status: response.status, data: response.data };
  } catch (error) {
    return { status: error.response.status, data: error.response.data };
  }
};

const signup = async (data) => {
  const response = await api.post('/auth/users/', data);
  return response.data;
};

const activate = async (uid, token) => {
  const response = await api.post('/auth/users/activation/', { uid, token });
  return response.data;
};
const requestPasswordReset = async (email) => {
  const response = await api.post('/auth/users/reset_password/', { email });
  return response.status;
};

const resetPasswordConfirm = async (newPassword, reNewPassword, uid, token) => {
  const response = await api.post('/auth/users/reset_password_confirm/', {
    new_password: newPassword,
    re_new_password: reNewPassword,
    uid,
    token,
  });
  return response.status;
};

export const authService = {
  login, signup, activate, requestPasswordReset, resetPasswordConfirm, resendActivationMail
};
