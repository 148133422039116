import { createSlice } from '@reduxjs/toolkit';
import { logout } from '../globalActions';

export const orgSlice = createSlice({
    name: 'org',
    initialState: {
        orgId: null,
        rootUserId: null,
        rootUserEmail: null,
    },
    reducers: {
        setOrgDetails: (state, action) => {
            state.orgId = action.payload.id;
            state.rootUserId = action.payload.root_user;
            state.rootUserEmail = action.payload.root_email;
        },
        clearOrgDetails: (state) => {
            state.orgId = null;
            state.rootUserId = null;
            state.rootUserEmail = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(logout, () => ({
            orgId: null,
            rootUserId: null,
            rootUserEmail: null
        }));
    }
});

export const { setOrgDetails, clearOrgDetails } = orgSlice.actions;

export default orgSlice.reducer;
