import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';

const Sidebar = (props) => {
    const [inactive, setInactive] = useState(false);
    useEffect(() => {
        if (inactive) {
            document.querySelectorAll(".sub-menu").forEach((el) => {
                el.classList.remove("active");
            });
        }
        props.onCollapse(inactive);
    }, [inactive, props]);
    const setColN = (item) => {
        props.parentCallBack(item);
    }
    return (
        <>
            <div className={`side-menu ${inactive ? "inactive" : ""}`}>
                <div ref={props.takeRef} onClick={() => setInactive(!inactive)} className="toggle-menu-btn">
                    {inactive ? (
                        <i className="bi bi-arrow-right-square"></i>
                    ) : (
                        <i className="bi bi-list"></i>
                    )}
                </div>
                <div className="main-menu">
                    <ul>
                        <li className="first">
                            <Link to="" className={`menu-item`}>
                                <div className="menu-icon">
                                <i className="bi bi-columns-gap"></i>
                                </div>
                                <span>AWS Accounts</span></Link>
                        </li>
                        <li>
                            <Link to="" onClick={() => setColN('d')} className={`menu-item ${(props.isColActive === 'd') ? "active" : ""}`}>
                                <div className="menu-icon">
                                {/* <i className="bi bi-columns-gap"></i> */}
                                </div>
                                <span>+ Add Accounts</span></Link>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};
export default Sidebar;
