import React from 'react';
import { createRoot } from 'react-dom/client';
import 'process';
import 'buffer';
import './index.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './redux/store';
import App from 'src/App';
import reportWebVitals from './reportWebVitals';
import LaunchingSoon from 'src/components/info/LaunchingSoon';

const isProduction = process.env.REACT_APP_PRODUCTION === 'true';

const RootComponent = isProduction ? App : LaunchingSoon;

const root = createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <RootComponent />
    </PersistGate>
  </Provider>
);

reportWebVitals();