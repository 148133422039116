// src/components/auth/ResendActivation.js

import React, { useState } from 'react';
import { Container, Row, Image, Button, FloatingLabel, Form, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { authService } from 'src/services/authService';
import Logo from "src/assets/images/logo.svg";
import { toast } from 'react-hot-toast';

function ResendActivation() {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const handleResendActivation = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const result = await authService.resendActivationMail(email);
            setLoading(false);
            if (result.status === 200) {
                toast.success(result.data.detail || 'Activation email resent successfully!');
            } else {
                toast.error(result.data.detail || 'Failed to resend activation email.');
            }
        } catch (error) {
            setLoading(false);
            toast.error('Failed to resend activation email.');
        }
    };

    return (
        <>
            <div className='loginWrap'>
                <Container className='desh-height'>
                    <Row className='d-flex justify-content-center align-items-center desh-height'>
                        <div className='col-12 col-md-6 col-lg-7 text-center mb-p-logo'>
                            <div className='mr-login'>
                                <Image src={Logo} />
                                <p className='login-heading-sub'>Please enter your email to resend the activation link</p>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-5 col-xxl-4'>
                            <div className='loginBox'>
                                <h1>Resend Activation Email</h1>
                                <Form onSubmit={handleResendActivation}>
                                    <FloatingLabel label="Email address" className="mb-2">
                                        <Form.Control
                                            className="form-style"
                                            type="email"
                                            placeholder="name@example.com"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                    </FloatingLabel>
                                    <Button variant="primary" className='mt-3 w-100' type="submit" disabled={loading}>
                                        {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Resend Email'}
                                    </Button>
                                    <div className='text-center mt-3'>
                                        <Link to="/login" className='f14 link-color'>Back to Login</Link>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default ResendActivation;
