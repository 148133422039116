import { createSlice } from '@reduxjs/toolkit';
import { logout } from '../globalActions';

export const awsAccountSlice = createSlice({
    name: 'awsAccount',
    initialState: {
        clientArn: '',
        accountId: '',
        computeCosts: { cost_last_month: "0.00", cost_past_year: "0.00" },

    },
    reducers: {
        setClientArn: (state, action) => {
            state.clientArn = action.payload;
        },
        setAccountId: (state, action) => {
            state.accountId = action.payload;
        },
        setComputeCosts: (state, action) => {
            state.computeCosts = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(logout, () => ({
            clientArn: '',
            accountId: '',
            computeCosts: { cost_last_month: "0.00", cost_past_year: "0" }
        }));
    }
});

export const { setClientArn, setAccountId, setComputeCosts } = awsAccountSlice.actions;

export default awsAccountSlice.reducer;
