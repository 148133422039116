import api from './api';

// Function to fetch organization details for the active user
const getOrganization = async (token) => {
    const config = {
        headers: { Authorization: `JWT ${token}` },
    };

    const response = await api.get('/aws/admin/org', config);
    return response.data;
};

const createOrganization = async (token) => {
    const config = {
        headers: { Authorization: `JWT ${token}` },
    };
    const response = await api.post('/aws/admin/org/', {}, config);
    return response.data;
};

export const orgService = {
    getOrganization, createOrganization
};
