import React from 'react';
import { useNavigate } from "react-router-dom";
import Logo from 'src/assets/images/logo.svg';
import 'src/assets/images/backgroundMobile.svg';
import {Link} from 'react-router-dom';
import { Dropdown } from "react-bootstrap";
import 'src/style/Dashboard.css';
const Header = (props) => {
  const setColN = (item) => {
      props.parentCallBack(item);
  }
  return (
  <>

<div className='header-fix d-flex justify-content-between align-items-center'>
          <div className="wrap-logo pl-lg-3"><Link to="/"><img src={Logo} className="img-fluid header-logo" alt="test" /></Link></div>
          {/* <div className='mr-3 mr-lg-4 search-wrap ml-auto col-md-3 pr-0  d-none d-md-none d-lg-block'>
            <input type="text" className='search-input' placeholder='Search' />
            <button type='button' className='search-btn'><i className="bi bi-search"></i></button>
          </div> */}
          <div className='d-flex align-items-center'>
            {/* <div className='me-3 me-lg-4 noti-wrap'>
              <Dropdown className="d-inline mx-0">
                <Dropdown.Toggle id="dropdown-autoclose-true" className='header-droparw'>
                  <i className="bi bi-bell icon-header"></i>
                  <div className='notification'></div>
                </Dropdown.Toggle>

                <Dropdown.Menu className='drop-alignmentNoti'>
                  <div className='heading'>Notifications</div>
                  <div className='drop-inner'>
                    <div><Link to="/">Lorem Ipsum has been the industry's standard dummy</Link></div>
                    <Dropdown.Divider className='mr-3'/>
                    <div><Link to="/">Lorem Ipsum has been the industry's standard dummy</Link></div>
                    <Dropdown.Divider className='mr-3'/>
                    <div><Link to="/">Lorem Ipsum has been the industry's standard dummy</Link></div>
                    <Dropdown.Divider className='mr-3'/>
                    <div><Link to="/">Lorem Ipsum has been the industry's standard dummy</Link></div>
                  </div>
                  <div className='text-center drop-footer'>View More Notifications</div>
                </Dropdown.Menu>
              </Dropdown>
            </div> */}
             <div className='me-4 me-lg-4'><i className="bi bi-person-circle icon-header"></i></div>
          </div>

        </div>
  </>)
}

export default Header;

