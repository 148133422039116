import React, { useState } from 'react';
import { Button } from '@mui/material';
import 'src/style/graphs.css';
import GroupsList from './GroupsList';
import { Link } from 'react-router-dom';

function AwsmoGroups(props) {

    return (
        <div className='margin-top-dash font-open'>
            <div className='h-scroller rightContainer'>
                <div className='m-3 m-lg-4'>
                    <span>
                        <Link to={`create`}>
                            <Button
                                variant="contained"
                                startIcon={<span>+</span>}
                                style={{
                                    marginBottom: '10px',
                                    backgroundColor: '#1976d2',
                                    borderRadius: '10px',
                                }}

                            >
                                Create New Group
                            </Button>
                        </Link>
                    </span>

                    <GroupsList />
                </div>
            </div>
        </div >
    );
}

export default AwsmoGroups;
