import React from "react";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import RandomColor from "./RandomColor";

const data = [
  { name: "Past 7 Days ", value: 20 },
  { name: "Week to Date ", value: 80 }
];
const COLORS = ["#00C2FA", "#007CBB"];

data.map(row => {
  const color = RandomColor();
  COLORS.push(color);
  return true;
})

export default function PieCharts() {
  const itemHtml = data.map((row, index) => {
    return (<>
      <div key={index} className="d-flex justify-content-start align-items-center  mb-2 me-3">
        <div>{row.value}%</div><div className="wrap-color" style={{ backgroundColor: COLORS[index % COLORS.length] }}></div><div className="txt-default">{row.name}</div>
      </div>
    </>);
  });
  return (
    <>
      <div>
        <div className="d-flex justify-content-start align-items-top">
          <PieChart width={280} height={150}>
            <Pie
              id={data.name}
              data={data}
              cx={137}
              cy={60}
              innerRadius={40}
              outerRadius={60}
              fill="#8884d8"
              paddingAngle={10}
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
          <div className="d-flex flex-wrap justify-content-start align-items-top">
            {itemHtml}
          </div>
        </div>

      </div>

    </>
  );
}
