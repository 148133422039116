import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, Badge, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { awsService } from 'src/services/awsService';

const NotificationDropdown = () => {
    const [notifications, setNotifications] = useState([]);
    const token = useSelector((state) => state.user.token);

    useEffect(() => {
        awsService.fetchNotifications(token)
            .then(res => setNotifications(res))
            .catch(err => console.error(err));
    }, []);

    const handleDelete = (notificationId) => {
        awsService.deleteNotification(notificationId, token)
            .then(() => {
                setNotifications(notifications.filter(notif => notif.id !== notificationId));
            })
            .catch(err => console.error(err));
    };

    const notificationCount = notifications.length;
    const notificationTooltip = (
        <Tooltip id="notification-tooltip">
            {notificationCount > 0 ? `${notificationCount} unread notifications` : 'No new notifications'}
        </Tooltip>
    );

    return (
        <Dropdown className="d-inline mx-0 notification-dropdown">
            <OverlayTrigger placement="bottom" overlay={notificationTooltip}>
                <Dropdown.Toggle
                    id="dropdown-autoclose-true"
                    className='header-droparw'
                    style={{
                        position: 'relative',
                        backgroundColor: 'transparent',
                        borderColor: 'transparent',
                        boxShadow: 'none'
                    }}
                >
                    <div style={{ position: 'relative', display: 'inline-block' }}>
                        {notificationCount > 0 && (
                            <Badge
                                pill
                                bg="danger"
                                style={{
                                    position: 'absolute',
                                    top: '0',
                                    right: '0',
                                    fontSize: '0.65em',
                                    transform: 'scale(0.7) translate(50%, -50%)'
                                }}
                            >
                                {notificationCount}
                            </Badge>
                        )}
                        <div style={{ backgroundColor: '#f5f5f5', borderRadius: '50%', padding: '8px' }}>
                            <NotificationsOutlinedIcon style={{ fontSize: '2rem', color: '#31708f' }} />
                        </div>
                    </div>
                </Dropdown.Toggle>
            </OverlayTrigger>
            <Dropdown.Menu className='drop-alignmentNoti' style={{ width: '500px', padding: '0', borderRadius: '0.5rem', boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.15)', maxHeight: '500px', overflowY: 'auto' }}>
                <div className='heading' style={{ fontSize: '1.2rem', fontWeight: 'bold', backgroundColor: '#f5f5f5', padding: '1rem', position: 'sticky', top: '0', zIndex: '1' }}>Notifications</div>
                {notifications.map(notification => (
                    <React.Fragment key={notification.id}>
                        <div className='d-flex justify-content-between align-items-start' style={{ padding: '0.75rem', margin: '1rem', backgroundColor: '#f8f9fa', borderRadius: '0.25rem', border: '1px solid #dee2e6' }}>
                            <div className='flex-grow-1' style={{ marginRight: '1rem' }}>
                                <Link to={notification.link} style={{ textDecoration: 'none', color: '#212529', fontWeight: 'bold' }}>
                                    {notification.title}
                                </Link>
                                <p style={{ color: '#6c757d', marginBottom: '0' }}>{notification.body}</p>
                            </div>
                            <DeleteIcon onClick={() => handleDelete(notification.id)} style={{ cursor: 'pointer', alignSelf: 'start' }} />
                        </div>
                        <Dropdown.Divider />
                    </React.Fragment>
                ))}
                <div style={{ backgroundColor: '#f5f5f5', padding: '1rem', position: 'sticky', bottom: '0', zIndex: '1' }}>
                    {notificationCount > 0 ? `${notificationCount} unread notifications` : 'No new notifications'}
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default NotificationDropdown;