import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Paper, Typography, Chip, Grid, Button, IconButton, Tooltip } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { awsService } from 'src/services/awsService';
import MaintenanceWindowModal from './MaintenanceWindowModal';
import EditIcon from '@mui/icons-material/Edit';

function MaintenanceGroups() {
    const [maintenanceWindows, setMaintenanceWindows] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedWindow, setSelectedWindow] = useState(null);
    const [highlightedId, setHighlightedId] = useState(null);


    const token = useSelector((state) => state.user.token);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchMaintenanceWindows = async () => {
            try {
                const windows = await awsService.fetchMaintenanceWindows(token);
                setMaintenanceWindows(windows);
            } catch (error) {
                console.error("Failed to fetch maintenance windows:", error);
            }
        };

        fetchMaintenanceWindows();
        if (!isModalOpen) {
            fetchMaintenanceWindows();
        }
    }, [token, isModalOpen]);

    const location = useLocation();
    const refs = useRef({});

    useEffect(() => {
        const highlightedId = location.state?.highlightedId;
        if (highlightedId) {
            setHighlightedId(highlightedId);
            const timer = setTimeout(() => setHighlightedId(null), 4000);
            setTimeout(() => {
                refs.current[highlightedId].scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                });
            }, 100);

            return () => clearTimeout(timer);
        }
    }, [location.state]);

    const handleCloseModal = () => {
        setSelectedWindow(null);
        setIsModalOpen(false);
    };

    const openEditModal = (window) => {
        setSelectedWindow(window);
        setIsModalOpen(true);
    };

    const openCreateModal = () => {
        setSelectedWindow(null);
        setIsModalOpen(true);
    };

    const formatRepeatsEvery = (repeatsEvery) => {
        if (repeatsEvery === 'ALTERNATE_WEEK') {
            return 'Alternate Week';
        }
        else if (repeatsEvery === 'NEVER') {
            return 'Never';
        }
        else if (repeatsEvery === 'WEEK') {
            return 'Week';
        }
        else if (repeatsEvery === 'MONTH') {
            return 'Month';
        }
    };

    return (
        <Box className='margin-top-dash font-open' sx={{ overflowX: 'auto' }}>
            <Box className='h-scroller rightContainer'>
                <div className='m-3 m-lg-4'>
                    <span>
                        <Button
                            variant="contained"
                            startIcon={<span>+</span>}
                            style={{
                                backgroundColor: '#1976d2',
                                borderRadius: '10px',
                            }}
                            onClick={openCreateModal}

                        >
                            Create Maintenance Window
                        </Button>
                    </span>
                </div>
                <MaintenanceWindowModal
                    open={isModalOpen}
                    handleClose={handleCloseModal}
                    window={selectedWindow}
                />
                <Box className='m-3 m-lg-4 desh-height rounded-cust'>
                    {maintenanceWindows.map((window, index) => (
                        <Paper
                            ref={el => refs.current[window.id] = el}
                            elevation={2}
                            key={window.id}
                            sx={{
                                mb: 2,
                                p: 3,
                                borderRadius: '8px',
                                position: 'relative',
                                animation: highlightedId === window.id ? 'highlightFade 3s ease-in-out' : 'none',
                            }}
                        >
                            <Tooltip title="Edit Maintenance Window">
                                <IconButton
                                    onClick={() => openEditModal(window)}
                                    sx={{ position: 'absolute', top: 10, right: 10 }}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" gutterBottom component="div" style={{ fontWeight: 'bold', fontSize: '1.25rem' }}>
                                        <Button
                                            variant="outlined"
                                            style={{ marginRight: '10px'}}
                                        >
                                            {window.id}
                                        </Button>
                                        {window.name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="body1" gutterBottom>
                                        Start Time
                                    </Typography>
                                    <Typography variant="body2" gutterBottom style={{ fontWeight: 'bold' }}>
                                        {moment(window.start).format('DD MMM YYYY HH:mm')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="body1" gutterBottom>
                                        End Time
                                    </Typography>
                                    <Typography variant="body2" gutterBottom style={{ fontWeight: 'bold' }}>
                                        {moment(window.end).format('DD MMM YYYY HH:mm')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="body1" gutterBottom>
                                        Repeats Every
                                    </Typography>
                                    <Typography variant="body2" gutterBottom style={{ fontWeight: 'bold' }}>
                                        {formatRepeatsEvery(window.repeats_every)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    {window.awsmo_groups.map(group => (
                                        <Chip
                                            key={group.id}
                                            label={`${group.name}`}
                                            clickable
                                            color="primary"
                                            onClick={() => navigate(`/groups/${group.id}`)}
                                            sx={{ marginRight: 1, marginTop: 1 }}
                                        />
                                    ))}
                                </Grid>
                            </Grid>
                        </Paper>
                    ))}
                </Box>
            </Box>
        </Box >
    );
}
export default MaintenanceGroups;
