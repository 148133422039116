import React, { useState } from 'react';
import { Container, Row, Image, Button, Alert } from 'react-bootstrap';
import Logo from "src/assets/images/logo.svg";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { authService } from 'src/services/authService';
function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the form from refreshing the page
        try {
            const status = await authService.requestPasswordReset(email);
            if (status === 204) {
                setMessage('Check your email for the reset password link.');
            } else {
                setMessage('Unexpected response from the server.');
            }
        } catch (error) {
            console.error('Failed to request password reset:', error);
            setMessage('Failed to request password reset. Please try again later.');
        }
    };

    const LinkLogin = () => {
        window.location.href = '/login';
    };
    const LinkReset = () => { window.location.href = "/awsmo/resetpassword/" }
    return (
        <>
            <div className='loginWrap'>
                <Container className='desh-height'>
                    <Row className='d-flex justify-content-center align-items-center desh-height'>
                        <div className='col-12 col-md-6 col-lg-7 text-center mb-p-logo'>
                            <div className='mr-login'>
                                <Image src={Logo} />
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-5 col-xxl-4'>
                            <div className='signupBox'>
                                <h1>Forgot Password</h1>
                                <p className='f14 subtext mt-4'>You will receive a link to your email address. Click the link to reset your password.</p>
                                <form onSubmit={handleSubmit}>
                                    <FloatingLabel label="Email address" className="mb-2">
                                        <Form.Control
                                            className="form-style"
                                            type="email"
                                            placeholder=""
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </FloatingLabel>
                                    <Button variant="primary" className='mt-4 w-100' type="submit">Reset Password</Button>
                                    {message && <Alert variant="info" className="mt-3">{message}</Alert>}
                                    <div className='text-center pt-4 mb-1'>
                                        <span className='f14'><a href="#" className='f14 link-color' onClick={LinkLogin}>Cancel</a></span>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </>
    )
}
export default ForgotPassword
