import React, { useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import SidebarDashboard from './SidebarDashboard';
import HeaderDashboard from './HeaderDashboard';

const Dashboard = () => {
  // State hooks for managing UI states
  const [inactive, setInactive] = useState(false); // Manages sidebar collapse state
  const [isColActive, setColActive] = useState('d'); // Tracks the active column in the layout
  const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent); // Checks if the device is mobile
  const inputRef = useRef(null) // Reference hook to interact with DOM elements, like triggering a click

  // Function to handle column activity changes, especially for mobile interactions
  const setColumnActive = (e) => {
    if (isMobile === true && e !== 'p') {
      inputRef.current.click()
    }
    setColActive(e);
  }

  const pathname = window.location.pathname.split("/")
  const pathLastName = pathname[pathname.length - 1]

  return (
    <>

      {!["login", "signup", "forgotpassword", "resetpassword", "welcome", "addaccount", "awsaccount"].includes(pathLastName) && (
        <header>
          <SidebarDashboard takeRef={inputRef} parentCallBack={setColumnActive} isColActive={isColActive} onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          />
          <HeaderDashboard parentCallBack={setColumnActive} isColActive={isColActive} />
        </header>
      )}

      {/* Conditionally render the sidebar and header for specific dashboard-related routes */}
      {(pathLastName === "dashboard") || (pathLastName === "accountawsid") || (pathLastName === "instancedetail") || (pathLastName === "verified") &&
        <header>
          <SidebarDashboard takeRef={inputRef} parentCallBack={setColumnActive} isColActive={isColActive} onCollapse={(inactive) => {
            setInactive(inactive);
          }} />
          <HeaderDashboard parentCallBack={setColumnActive} isColActive={isColActive} />
        </header>
      }

      <Outlet />
    </>
  )
}

export default Dashboard
