import React from 'react';
import './style/MainStyle.css'
import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter as Router } from "react-router-dom";

import RoutesComponent from 'src/routes/RoutesComponent';

function App() {
  return (
    <Router basename='/'>
      <RoutesComponent />
    </Router>
  );
}

export default App;
