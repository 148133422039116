import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from 'src/redux/globalActions';
import toast from 'react-hot-toast';
import { useState, useEffect } from 'react';

const useAuth = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an authentication check
        setTimeout(() => setIsLoading(false), 1000); // Remove or adjust based on real auth check
    }, []);

    const performLogout = () => {
        dispatch(logout());
        toast.success('Logged out successfully.');
        navigate('/login');
    };

    return { isAuthenticated, isLoading, performLogout };
};

export default useAuth;