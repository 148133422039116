import React from 'react';
import Logo from "src/assets/images/logo.svg";

const LoadingScreen = ({ message, linkText, linkUrl }) => (
    <div className="loading-screen">
        <img src={Logo} alt="Loading..." className="center-logo" />
        <p className="loading-message">{message}</p>
        {linkUrl && linkText && (
            <a href={linkUrl} className="support-link" style={{ marginTop: '10px', display: 'block', textAlign: 'center' }}>
                {linkText}
            </a>
        )}
    </div>
);

export default LoadingScreen;
