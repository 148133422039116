import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Grid,
    Select,
    MenuItem,
    FormControl,
    InputLabel
} from '@mui/material';
import { useSelector } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { awsService } from 'src/services/awsService';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';

function MaintenanceWindowModal({ open, handleClose, window }) {

    const isEditing = window != null; // Check if the modal is for editing or creating a new window
    const [name, setName] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [repeatsEvery, setRepeatsEvery] = useState('');

    const formatRepeatsEvery = (repeatsEvery) => {
        if (repeatsEvery === 'ALTERNATE_WEEK') {
            return 'Alternate Week';
        }
        else if (repeatsEvery === 'NEVER') {
            return 'Never';
        }
        else if (repeatsEvery === 'WEEK') {
            return 'Week';
        }
        else if (repeatsEvery === 'MONTH') {
            return 'Month';
        }
    };
    useEffect(() => {
        setName(window?.name || '');
        setStartDate(window?.start ? dayjs(window.start) : null);
        setEndDate(window?.end ? dayjs(window.end) : null);
        setRepeatsEvery(formatRepeatsEvery(window?.repeats_every) || '');
    }, [window]);

    const token = useSelector((state) => state.user.token);

    const handleSubmit = async () => {
        const payload = {
            start: startDate?.toISOString(),
            end: endDate?.toISOString(),
            name,
            repeats_every: repeatsEvery.toUpperCase().replace(' ', '_')
        };

        try {
            let data;
            if (isEditing) {
                // Call the update API
                data = await awsService.updateMaintenanceWindow(window.id, payload, token);
            } else {
                // Call the create API
                data = await awsService.createMaintenanceWindow(payload, token);
            }

            if (data && data.id) {
                toast.success(`Maintenance window ${isEditing ? 'updated' : 'created'} successfully.`);
                handleClose();
            } else {
                toast.error(`Failed to ${isEditing ? 'update' : 'create'} maintenance window.`);
            }
        } catch (error) {
            toast.error(`An error occurred while ${isEditing ? 'updating' : 'creating'} the maintenance window. Please try again.`);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: { borderRadius: 15, padding: 10 }
                }}

            >
                {isEditing ?
                    <DialogTitle>Edit Maintenance Window</DialogTitle>
                    :
                    <DialogTitle>Create Maintenance Window</DialogTitle>
                }
                <DialogContent>
                    <Grid container spacing={2} sx={{ padding: 1 }}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Maintenance Window Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DateTimePicker
                                label="Start Time"
                                value={startDate}
                                onChange={setStartDate}
                                renderInput={(props) => <TextField fullWidth {...props} />}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DateTimePicker
                                label="End Time"
                                value={endDate}
                                onChange={setEndDate}
                                renderInput={(props) => <TextField fullWidth {...props} />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="repeats-every-label">Repeats Every</InputLabel>
                                <Select
                                    labelId="repeats-every-label"
                                    id="repeats-every-select"
                                    value={repeatsEvery}
                                    label="Repeats Every"
                                    onChange={(e) => setRepeatsEvery(e.target.value)}
                                >
                                    <MenuItem value="Month">Month</MenuItem>
                                    <MenuItem value="Week">Week</MenuItem>
                                    <MenuItem value="Alternate Week">Alternate Week</MenuItem>
                                    <MenuItem value="Never">Never</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        variant="outlined"
                        sx={{ borderRadius: 20, textTransform: 'none' }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        sx={{ borderRadius: 20, textTransform: 'none' }}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </LocalizationProvider>
    );
}

export default MaintenanceWindowModal;
